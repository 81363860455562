/* eslint-disable default-case */
import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Form, Grid } from 'semantic-ui-react'
import { useFormik } from 'formik'
import ReactCrop from 'react-image-crop'
import useDebounce from '../../../../../src/hooks/useDebounce'
import { canvasPreview } from '../../../../components/canvasPreview'
import InputField from '../../../../components/common/InputField/InputField'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import BorderButton from '../../../../components/button/BorderButton'
import { toast } from '../../../../components/Toast/Toast'
import { WinerMakerAction } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import { IconDelete, IconCrop, AddIcon } from '../../../../Assets/image/svgimages/sVGImg'
import { IconAdd } from '../../../../Assets/image/svgImg'

const winerSchema = Yup.object().shape({
  background_img1: Yup.mixed(),
  background_img2: Yup.mixed(),
  background_img3: Yup.mixed(),
  background_img4: Yup.mixed(),
  background_img5: Yup.mixed(),
  background_img6: Yup.mixed(),
  background_img7: Yup.mixed(),
  background_img8: Yup.mixed(),
  description_1: Yup.mixed(),
  description_2: Yup.mixed(),
  description_3: Yup.mixed(),
  description_4: Yup.mixed(),
  description_5: Yup.mixed(),
  description_6: Yup.mixed(),
  description_7: Yup.mixed(),
  description_8: Yup.mixed(),
})

const GallaryStepThird = (props) => {
  let { userId, setStep, winerDetails, getWinerAllDetails, setIsLoader, status } = props
  const [crop, setCrop] = useState({})
  const [completedCrop, setCompletedCrop] = useState({})
  const [currentlyCropping, setCurrentlyCropping] = useState()
  const imgRef = useRef([])
  const previewCanvasRef = useRef([])
  const blobUrlRef = useRef([])
  const { saveProfileOldData, updateGalleryWinerDetail, uploadFileGc } = WinerMakerAction
  const [allImages, setAllImages] = useState([])
  const [oldDataStep3, setOldDataStep3] = useState({})
  const dispatch = useDispatch()

  const inputRefImageOne = useRef()
  const inputRefImageTwo = useRef()
  const inputRefImageThree = useRef()
  const inputRefImageFour = useRef()
  const inputRefImageFive = useRef()
  const inputRefImageSix = useRef()
  const inputRefImageSeven = useRef()
  const inputRefImageEight = useRef()

  useDebounce(
    async () => {
      if (
        completedCrop[currentlyCropping]?.width &&
        completedCrop[currentlyCropping]?.height &&
        imgRef.current[currentlyCropping] &&
        previewCanvasRef.current[currentlyCropping]
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current[currentlyCropping],
          previewCanvasRef.current[currentlyCropping],
          completedCrop[currentlyCropping],
        )
      }
    },
    100,
    [completedCrop],
  )

  const uploadCropped = (index) => {
    if (!previewCanvasRef.current[index]) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current[index]?.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        if (blobUrlRef.current[index]) {
          URL.revokeObjectURL(blobUrlRef.current[index])
        }
        blobUrlRef.current[index] = URL.createObjectURL(blob)
        uploadImageApi(blob, index + 1)
      },
      { useCORS: true },
      'image/jpeg',
    )
  }

  useEffect(() => {
    if (winerDetails.length > 0) {
      let allImages = winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta
      setAllImages(
        allImages && allImages.length > 0
          ? allImages
          : [
              {
                background_img: '',
              },
            ],
      )

      let stepData = {
        header: winerDetails[0]?.creator_gallery_meta?.header,
      }
      setOldDataStep3(stepData)
    }
  }, [winerDetails, status])

  const wineGallaryFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,
    initialValues: {
      header: ' ',
      background_img1:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[0]?.imageUrl || '',
      background_img2:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[1]?.imageUrl || '',
      background_img3:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[2]?.imageUrl || '',
      background_img4:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[3]?.imageUrl || '',
      background_img5:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[4]?.imageUrl || '',
      background_img6:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[5]?.imageUrl || '',
      background_img7:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[6]?.imageUrl || '',
      background_img8:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[7]?.imageUrl || '',
      description_1:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[0]?.description ||
        '',
      description_2:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[1]?.description ||
        '',

      description_3:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[2]?.description ||
        '',

      description_4:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[3]?.description ||
        '',

      description_5:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[4]?.description ||
        '',

      description_6:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[5]?.description ||
        '',

      description_7:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[6]?.description ||
        '',

      description_8:
        winerDetails?.[0]?.creator_gallery_meta?.creator_gallery_images_meta?.[7]?.description ||
        '',
    },
    onSubmit: (values) => {
      values['userId'] = userId
      let data = []
      if (values.background_img1) {
        data.push({
          url: values.background_img1,
          description: values.description_1,
        })
      }
      if (values.background_img2) {
        data.push({
          url: values.background_img2,
          description: values.description_2,
        })
      }
      if (values.background_img3) {
        data.push({
          url: values.background_img3,
          description: values.description_3,
        })
      }
      if (values.background_img4) {
        data.push({
          url: values.background_img4,
          description: values.description_4,
        })
      }
      if (values.background_img5) {
        data.push({
          url: values.background_img5,
          description: values.description_5,
        })
      }
      if (values.background_img6) {
        data.push({
          url: values.background_img6,
          description: values.description_6,
        })
      }
      if (values.background_img7) {
        data.push({
          url: values.background_img7,
          description: values.description_7,
        })
      }
      if (values.background_img8) {
        data.push({
          url: values.background_img8,
          description: values.description_8,
        })
      }
      values.data = data

      let finalData = {
        header: values.header,
        data: data,
        userId: userId,
      }

      addGallaryApi(finalData)
    },
  })

  const addGallaryApi = (values) => {
    setIsLoader(true)

    dispatch(updateGalleryWinerDetail(values)).then((res) => {
      setIsLoader(false)
      if (status !== 0) {
        saveStepThirdData(values)
      }
      setStep(4)
      getWinerAllDetails()
    })
  }

  const saveStepThirdData = (wineMakerNew) => {
    let newData = { ...wineMakerNew }
    delete newData.userId
    let details = {
      prevData: oldDataStep3,
      newData: newData,
      formType: 'form_3',
      userId: userId,
    }

    dispatch(saveProfileOldData(details)).then((res) => {})
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG']
    return ext.some((el) => icon.endsWith(el))
  }

  const uploadImageApi = (file, type) => {
    let formData = new FormData()
    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      setIsLoader(false)

      switch (type) {
        case 1: {
          wineGallaryFormik.setFieldValue('background_img1', res?.data.data[0]?.url)
          break
        }
        case 2: {
          wineGallaryFormik.setFieldValue('background_img2', res?.data.data[0]?.url)
          break
        }
        case 3: {
          wineGallaryFormik.setFieldValue('background_img3', res?.data.data[0]?.url)
          break
        }
        case 4: {
          wineGallaryFormik.setFieldValue('background_img4', res?.data.data[0]?.url)
          break
        }
        case 5: {
          wineGallaryFormik.setFieldValue('background_img5', res?.data.data[0]?.url)
          break
        }
        case 6: {
          wineGallaryFormik.setFieldValue('background_img6', res?.data.data[0]?.url)
          break
        }
        case 7: {
          wineGallaryFormik.setFieldValue('background_img7', res?.data.data[0]?.url)
          break
        }
        case 8: {
          wineGallaryFormik.setFieldValue('background_img8', res?.data.data[0]?.url)
          break
        }
      }
    })
  }

  const uploadFileToS3 = async (input, type) => {
    input.persist()
    setIsLoader(true)

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      input.target.value = null
      toast.error('File extension not supported!')
      setIsLoader(false)
    } else {
      uploadImageApi(file, type)
    }
  }

  const gotToNextPage = () => {
    if (winerDetails.length > 0) {
      setStep(4)
    } else {
      toast.error('Please add required information')
    }
  }

  const deleteGallaryImage = (type) => {
    switch (type) {
      case 1: {
        inputRefImageOne.current.value = ''
        wineGallaryFormik.setFieldValue('background_img1', '')
        break
      }
      case 2: {
        inputRefImageTwo.current.value = ''
        wineGallaryFormik.setFieldValue('background_img2', '')
        break
      }
      case 3: {
        inputRefImageThree.current.value = ''
        wineGallaryFormik.setFieldValue('background_img3', '')
        break
      }
      case 4: {
        inputRefImageFour.current.value = ''
        wineGallaryFormik.setFieldValue('background_img4', '')
        break
      }
      case 5: {
        inputRefImageFive.current.value = ''
        wineGallaryFormik.setFieldValue('background_img5', '')
        break
      }
      case 6: {
        inputRefImageSix.current.value = ''
        wineGallaryFormik.setFieldValue('background_img6', '')
        break
      }
      case 7: {
        inputRefImageSeven.current.value = ''
        wineGallaryFormik.setFieldValue('background_img7', '')
        break
      }
      case 8: {
        inputRefImageEight.current.value = ''
        wineGallaryFormik.setFieldValue('background_img8', '')
        break
      }
    }
  }

  const addWineryGallery = () => {
    if (allImages.length === 8) return

    const copyAllImages = [...allImages]
    copyAllImages.push({
      img_url: '',
      description: '',
    })

    setAllImages(copyAllImages)
  }

  const getRef = (index) => {
    let ref
    switch (index) {
      case 2:
        ref = inputRefImageTwo
        break

      case 3:
        ref = inputRefImageThree
        break

      case 4:
        ref = inputRefImageFour
        break

      case 5:
        ref = inputRefImageFive
        break

      case 6:
        ref = inputRefImageSix
        break

      case 7:
        ref = inputRefImageSeven
        break

      case 8:
        ref = inputRefImageEight
        break

      default:
        ref = inputRefImageOne
        break
    }
    return ref
  }

  return (
    <div className='block' id='s3'>
      <div className='sectionInfo-people'>
        <h3 className='heading'>Gallery</h3>
        <p className='block__quantity'>Max quantity 8 images</p>
      </div>
      <div className='block__addCalleryBtn'>
        <ButtonCustom
          disabled={allImages.length === 8}
          customClass={'minimal'}
          leftIcon={<IconAdd />}
          title='Add more gallery photo'
          onClick={() => addWineryGallery()}
        />
      </div>
      <Form onSubmit={wineGallaryFormik.handleSubmit}>
        <Grid>
          <Grid.Column mobile={16} tablet={10} computer={16} className='padd-right'>
            {allImages.map((imgData, index) => (
              <div className='gallerySect' key={`gallery-${imgData.background_img}`}>
                <div className='gallerySect__rowBoard'>
                  <div className='gallerySect__imgInput'>
                    <div className='uploadField'>
                      <div className='profilePic'>
                        <div className='profilePic-inner'>
                          <input
                            id={`background_img${index + 1}`}
                            type='file'
                            name={`label${index + 1}`}
                            className='input__input'
                            onChange={(e) => uploadFileToS3(e, index + 1)}
                            formEncType='multipart/form-data'
                            ref={getRef(index + 1)}
                            title={
                              wineGallaryFormik.values[`background_img${index + 1}`] === ''
                                ? 'No file choosen'
                                : ''
                            }
                          />

                          <label
                            className={
                              wineGallaryFormik.values?.[`background_img${index + 1}`] !== ''
                                ? 'file-input__prof hidden BAckImageSet'
                                : 'file-input__prof'
                            }
                            htmlFor='file-input'
                          />
                          <div className='down-button' />

                          <ReactCrop
                            crop={crop[index]}
                            onComplete={(c) =>
                              setCompletedCrop((prev) => ({ ...prev, [index]: c }))
                            }
                            onChange={(c) => {
                              setCrop((prev) => ({ ...prev, [index]: c }))
                              setCurrentlyCropping(index)
                            }}
                            aspect={10 / 7}
                            style={{ zIndex: 9 }}
                          >
                            <img
                              ref={(el) => (imgRef.current[index] = el)}
                              src={wineGallaryFormik.values?.[`background_img${index + 1}`]}
                              alt=''
                              crossOrigin='anonymous'
                              width='100%'
                              height='auto'
                            />
                          </ReactCrop>
                          {!!completedCrop[index] && (
                            <>
                              <div>
                                <canvas
                                  ref={(el) => (previewCanvasRef.current[index] = el)}
                                  style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop[index].width,
                                    height: completedCrop[index].height,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {wineGallaryFormik.touched?.[`background_img${index + 1}`] && (
                            <p
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                margin: 'auto',
                                position: 'absolute',
                                textAlign: 'center',
                                marginTop: '5px',
                                width: '100%',
                              }}
                            >
                              {wineGallaryFormik.errors?.[`background_img${index + 1}`]}
                            </p>
                          )}
                          <div className='imageActions'>
                            {wineGallaryFormik.values?.[`background_img${index + 1}`] !== '' && (
                              <>
                                {!!completedCrop[index] && (
                                  <button
                                    className='imageActions__button'
                                    type='button'
                                    onClick={() => uploadCropped(index)}
                                  >
                                    <IconCrop />
                                  </button>
                                )}
                                <button
                                  className='imageActions__button'
                                  type='button'
                                  onClick={() => deleteGallaryImage(index + 1)}
                                >
                                  <IconDelete />
                                </button>
                                <button
                                  className='imageActions__button'
                                  type='button'
                                  onClick={() => getRef(index + 1).current?.click()}
                                >
                                  <AddIcon />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className='gallerySect__imgDetails'>
                      Image: <br /> Max size 1080 x 1080, max weight 30mb
                      <br /> Crop ratio: 10/7
                    </p>
                  </div>
                  <div className='gallerySect__imgDescript'>
                    <InputField
                      id={`description_${index + 1}`}
                      label='Photo description'
                      placeholder='Title Ex. Meet our Winery, Explore our Winery, Gallery'
                      type='text'
                      onChange={(event) => {
                        wineGallaryFormik.setFieldValue(
                          `description_${index + 1}`,
                          event.target.value,
                        )
                      }}
                      value={wineGallaryFormik.values[`description_${index + 1}`]}
                      formik={wineGallaryFormik}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Grid.Column>
        </Grid>
        <div className='btns-spacing'>
          <BorderButton
            text='Previous step'
            className='addNew_Btn'
            type='button'
            onClick={() => setStep(2)}
          />
          <ButtonCustom title='Continue' customClass='saveBtn' type={'submit'} />
          {winerDetails[0]?.creator_gallery_meta !== null &&
            Object.keys(winerDetails[0]?.creator_gallery_meta).length > 0 && (
              <ButtonCustom
                type='button'
                title='Skip'
                customClass='saveBtn'
                onClick={() => gotToNextPage()}
              />
            )}
        </div>
      </Form>
    </div>
  )
}
export default GallaryStepThird
