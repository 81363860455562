import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

import { ButtonCustom } from 'components/button/ButtonCustom'
import { toast } from 'components/Toast/Toast'

import comCSS from './CreateBulk.module.scss'
import { Plusicon } from 'Assets/image/svgImg'

const CreateBulk = ({ actionFn = () => {} }) => {
  const dispatch = useDispatch()
  const [isCreateBulk, setIsCreateBulk] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [resFileUrl, setResFileUrl] = useState(null)

  const handleUpload = () => {
    if (fileList.length === 0) return
    const formData = new FormData()
    formData.append('file', fileList[0])

    setUploading(true)
    setResFileUrl(null)
    dispatch(actionFn(formData))
      .then((res) => {
        const data = res.data?.data
        if (data?.error) {
          toast.error(data?.message)
        } else if (res?.data?.error) {
          toast.error(res?.data?.message)
        } else {
          toast.success(data?.message)
        }

        setResFileUrl(data?.fileUrl)
      })
      .catch(() => {
        toast.error('upload failed.')
      })
      .finally(() => {
        setFileList([])
        setUploading(false)
      })
  }

  const props = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const isXLSX = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
      ].some((el) => el === file.type)
      if (!isXLSX) {
        toast.error(`${file.name} is not a xlsx file`)
        return null
      }
      setResFileUrl(null)
      setFileList([file])
      return false
    },
    fileList,
    className: cn(comCSS.uploadFileBtnWrapper),
  }
  return (
    <div className={cn(comCSS.wrapper)}>
      <div className={cn(comCSS.btnWrapper)}>
        <ButtonCustom
          onClick={() => setIsCreateBulk(true)}
          title='Create Bulk'
          leftIcon={<Plusicon />}
        />
      </div>
      {isCreateBulk && (
        <div className={cn(comCSS.uploadWrapper)}>
          <h3>Bulk Creation</h3>
          <div className={cn(comCSS.btnsWrapper)}>
            <Upload {...props}>
              <ButtonCustom
                title='Select File'
                customClass={cn(comCSS.uploadFileBtn)}
                leftIcon={<UploadOutlined />}
              />
            </Upload>

            <ButtonCustom
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              customClass={cn(comCSS.submitBtn)}
              title={uploading ? 'Uploading...' : 'Upload'}
            />
          </div>
          {resFileUrl && (
            <div className={cn(comCSS.resultWrapper)}>
              <a
                href={resFileUrl}
                target='_blank'
                rel='noreferrer'
                className={cn(comCSS.resultLink)}
              >
                <strong>Check Results</strong>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CreateBulk
