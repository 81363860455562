import React, { useState, useEffect, useRef } from 'react'
import { Grid, Popup, Form } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import cn from 'classnames'
import { ButtonCustom } from '../../../../components/button/ButtonCustom'
import InputField from '../../../../components/common/InputField/InputField'
import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import { ReactComponent as IconBack } from '../../../../Assets/image/svgimages/icon_back.svg'
import { WinerMakerAction, Bottles } from '../../../../redux/_actions'
import { useDispatch } from 'react-redux'
import { toast } from '../../../../components/Toast/Toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import doc from '../../../../images/doc.png'
import ActualVersion from './ActualVersion/ActualVersion'
import moment from 'moment'
import '../../winemakers/addWinemaker/AddWinemaker.scss'
import './EditProfilePage.scss'
import { IconDelete, IconCrop, AddIcon } from '../../../../Assets/image/svgimages/sVGImg'
import Info_Icon from '../../../../Assets/image/Info_Icon.svg'
import photo_colr from '../../../../Assets/image/photo_colr.png'
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReactCrop from 'react-image-crop'
import useDebounce from '../../../../../src/hooks/useDebounce'
import { canvasPreview } from '../../../../components/canvasPreview'
import { ShowError } from 'components/ShowError'
import { useHistory } from 'react-router'
import { tagCustomEventSendPulse } from '../../../../utils/sendPulseApi/sendPulseApi'
import { SENDPULSE_EVENTS } from '../../../../utils/sendPulseApi/constants/constants'

const winerSchema = Yup.object().shape({
  wallet_id: Yup.string(),
  country: Yup.string().required('This field is required'),
  winery_name: Yup.string().trim().required('This field is required'),
  location: Yup.string().required('This field is required'),
  winemaker_name: Yup.string().required('This field is required'),
  profile_image: Yup.string().nullable(),
  email: Yup.string().trim().required('Email cannot be empty').email('Email has to be valid'),
  phone_no: Yup.string().required('This field is required'),
  contract_file: Yup.string().nullable(),
  vat: Yup.string().nullable(),
  registration_number: Yup.string().nullable(),
  registration_country: Yup.number().nullable(),
  winery_address: Yup.string().nullable(),
})

const EditProfilePage = ({ createsNew = false }) => {
  const [country_code, setCountryCode] = useState('')
  const [phone_code, setPhoneCode] = useState('')
  const history = useHistory()
  // crop start
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const blobUrlRef = useRef('')
  // crop end

  // const { id } = useParams()
  const dispatch = useDispatch()

  const {
    getWinerDetails,
    saveProfileOldData,
    updateWinerProfileData,
    getDetailsLogs,
    getWinerRegistrationCountries,
  } = WinerMakerAction

  const inputRefProfileImage = React.useRef()
  const [isLoader, setIsLoader] = useState(false)

  const [country, setCountry] = useState('')
  const [regionList, setRegionList] = useState([])
  const [allCountry, setAllCountry] = useState([])
  const [registrationCountries, setRegistrationCountries] = useState([])
  const [countryWithRegion, setCountryWithRegion] = useState([])
  const [winerData, setWinerData] = useState({})
  const [showBottom, setShowBottom] = useState(true)
  const [isActive, setActive] = useState(false)
  const userId = history?.location?.state?.userId
  const [oldData, setOldData] = useState({})
  const [logsData, setLogsData] = useState({})
  const { getBottleDropDownSpecifications } = Bottles
  const inputRefIntentFile = React.useRef()

  useDebounce(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const uploadCropped = () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob(
      (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)
        uploadImageApi(blob)
      },
      { useCORS: true },
      'image/jpeg',
    )
  }

  useEffect(() => {
    getBottlesSpecification()
  }, [])
  const getBottlesSpecification = () => {
    dispatch(getBottleDropDownSpecifications()).then((res) => {
      const details = res.data
      const keys = Object.keys(details.regionList)
      setCountryWithRegion(details.regionList)
      setAllCountry(keys)
      getwinerDetails()
    })
  }

  const getRegistrationCountries = () => {
    dispatch(getWinerRegistrationCountries()).then(({ data }) => {
      setRegistrationCountries(data)
    })
  }
  useEffect(() => {
    getRegistrationCountries()
  }, [])

  useEffect(() => {
    // setting regions default value
    if (Object.keys(countryWithRegion).length) {
      for (let key in countryWithRegion) {
        if (key === winerData?.creator_meta?.country) {
          setRegionList(countryWithRegion[key])
        }
      }
    }
  }, [winerData])

  const ToggleClass = () => {
    setActive(!isActive)
  }

  useEffect(() => {
    if (userId === undefined) {
      // history.push('/panel/winelist')
    } else {
      getLogsData()
    }
  }, [])

  const getLogsData = () => {
    setIsLoader(true)

    let data = {
      formType: 'profile',
      userId: userId,
    }
    dispatch(getDetailsLogs(data)).then(
      (res) => {
        setIsLoader(false)
        setLogsData(res?.data.data)
      },
      (error) => {
        setIsLoader(false)
      },
    )
  }

  const wineFormik = useFormik({
    enableReinitialize: true,
    validationSchema: winerSchema,

    initialValues: {
      wallet_id: winerData?.wallet?.address || '',
      country: winerData?.creator_meta?.country,
      winery_name: winerData?.creator_meta?.winery_name,
      profile_image: winerData?.profileImage,
      email: winerData?.creator_meta?.commercial_email,
      main_contact_person: winerData?.creator_meta?.main_contact_person,

      phone_no: `+${winerData?.creator_meta?.phone_code}${winerData?.creator_meta?.commercial_phone_number}`,
      contract_file: createsNew ? null : winerData?.creator_meta?.document,
      winemaker_name: winerData?.fullname || '',
      vat: winerData?.creator_meta?.vat,
      registration_number: winerData?.creator_meta?.registration_number,
      registration_country: winerData?.creator_meta?.registration_country,
      winery_address: winerData?.creator_meta?.winery_address,
      location: winerData?.creator_meta?.location,
    },
    onSubmit: (values) => {
      let data = {
        commercial_email: values.email,
        commercial_phone_number: values.phone_no.replace('+' + phone_code, ''),
        phone_code: phone_code,
        country_code: country_code,
        contract: values.contract_file,
        country: values.country,
        location: values.location,
        profile_image: values.profile_image,
        user_id: userId,
        winery_name: values.winery_name,
        winemaker_name: values.winemaker_name,
        isAlreadyWinemaker: true,
        winery_address: values.winery_address,
        vat: values.vat,
        registration_number: values.registration_number,
        registration_country: Number(values.registration_country),
        main_contact_person: values.main_contact_person,
      }
      if (wineFormik.values.phone_no && isValidPhoneNumber(wineFormik.values.phone_no)) {
        if (userId !== undefined && userId !== null) {
          data.isUpdateCall = true
          updateWinemaker(data)
        } else if (wineFormik.values.phone_no && isValidPhoneNumber(wineFormik.values.phone_no)) {
          addWinemaker(data)
        }
      }
    },
  })

  const updateWinemaker = (wineMakerData) => {
    dispatch(updateWinerProfileData(wineMakerData)).then((res) => {
      toast.success(res.data.message)
      // history.push('/panel/WineList')
      saveUsersOldProfile(wineMakerData)
    })
  }

  const addWinemaker = (wineMakerData) => {
    const { addWinemaker } = WinerMakerAction
    dispatch(addWinemaker(wineMakerData)).then(async (res) => {
      toast.success(res?.data?.message)
      await tagCustomEventSendPulse({
        eventLink: SENDPULSE_EVENTS.WINERY_ACCOUNT,
        email: wineMakerData?.commercial_email,
        customData: { winery_name: wineMakerData?.winery_name },
      })

      history.push('/panel/WineList')
      if (userId) {
        saveUsersOldProfile(wineMakerData)
      }
    })
  }

  const saveUsersOldProfile = (wineMakerNew) => {
    let details = {
      prevData: oldData,
      newData: wineMakerNew,
      formType: 'profile',
      userId: userId,
    }

    dispatch(saveProfileOldData(details)).then((res) => {
      setIsLoader(false)
      history.push('/panel/WineList')
    })
  }

  const isImage = (icon) => {
    const ext = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG']
    return ext.some((el) => icon.endsWith(el))
  }

  const handleCountryChange = (e) => {
    wineFormik.setFieldValue('country', e.target.value)
    setCountry(e.target.value)
    wineFormik.setFieldValue('location', '')
    wineFormik.setFieldValue('appellation', '')

    for (let key in countryWithRegion) {
      if (key === e.target.value) {
        setRegionList(countryWithRegion[key])
      }
    }
  }

  const handleRegionChange = (e) => {
    wineFormik.setFieldValue('location', e.target.value)
  }

  const uploadFileToS3 = async (input) => {
    input.persist()

    let file = input.target.files[0]
    let fname = file.name

    if (!isImage(fname)) {
      toast('File extension not supported!')
      input.target.value = null

      setIsLoader(false)
    } else {
      // upload api temp added for no validation
      uploadImageApi(file)
    }
  }

  const uploadImageApi = (file) => {
    const { uploadFileGc } = WinerMakerAction
    let formData = new FormData()

    formData.append('folderName', 'testing')
    formData.append('file', file)

    dispatch(uploadFileGc(formData)).then((res) => {
      wineFormik.setFieldValue('profile_image', res?.data.data[0]?.url)
      setIsLoader(false)
    })
  }

  const getwinerDetails = async () => {
    if (!createsNew && userId !== null) {
      dispatch(getWinerDetails({ id: userId })).then((res) => {
        let oldData = { ...res.data.data }
        setWinerData(res.data.data)

        setPhoneCode(oldData?.creator_meta?.phone_code) // for mobile input field
        setCountryCode(oldData?.creator_meta?.country_code) //for mobile input field

        let data = {
          commercial_email: oldData?.email,
          commercial_phone_number: oldData?.creator_meta?.commercial_phone_number,
          contract: oldData?.creator_meta?.document,
          country: oldData?.creator_meta?.country,
          location: oldData?.creator_meta?.location,
          profile_image: oldData?.profileImage,
          winery_name: oldData?.creator_meta?.winery_name,
          winemaker_name: oldData.fullname,
          winery_address: oldData?.creator_meta?.winery_address,
          vat: oldData?.creator_meta?.vat,
          registration_number: oldData?.creator_meta?.registration_number,
          registration_country: oldData?.creator_meta?.registration_country,
          main_contact_person: oldData?.creator_meta?.main_contact_person,
        }
        setOldData(data) // saving as old before edit
        setCountry(res?.data?.data?.creator_meta?.country)
      })
    }
  }

  const deleteProfileImage = () => {
    inputRefProfileImage.current.value = ''
    wineFormik.setFieldValue('profile_image', '')
  }
  const uploadFileToS3File = (input) => {
    const { uploadFileGc } = WinerMakerAction

    let formData = new FormData()

    let image = input.target.files[0]

    let file = input.target.files[0]
    let fileSize = file.size / 1024 / 1024

    if (
      image.type === 'application/pdf' ||
      image.type === 'application/msword' ||
      image.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      formData.append('folderName', 'testing')
      formData.append('file', image)
      if (fileSize <= 16) {
        dispatch(uploadFileGc(formData)).then((res) => {
          wineFormik.setFieldValue('contract_file', res?.data.data[0]?.url)
          setIsLoader(false)
        })
      } else {
        setIsLoader(false)
        toast.error('File should not more than 16 mb')
      }
    } else {
      setIsLoader(false)
      toast.error('File format not accepted')
    }
  }
  const deleteCoverImageContent = () => {
    inputRefIntentFile.current.value = ''
    wineFormik.setFieldValue('contract_file', '')
  }
  return (
    <>
      <MainLayout>
        <Row>
          <Col xs={24}>
            <div className='winemakers-main create-public-page edt'>
              <div
                className={cn('left', {
                  brdr: isActive,
                  hidden: createsNew,
                })}
              >
                <div className='top-controll'>
                  <Link to='/panel/WineList' className='back-btn'>
                    <IconBack /> Back <span>to winemaker list</span>
                  </Link>
                </div>
                <div className='logs-controller'>
                  <div className='top'>
                    <h3 className='common-sub-heading'>Logs</h3>

                    <Link
                      className={cn('logs-toggler', {
                        active: isActive,
                      })}
                      onClick={() => {
                        setShowBottom((currentShow) => !currentShow)
                        ToggleClass()
                      }}
                    ></Link>
                  </div>
                  {showBottom && (
                    <div className='bottom'>
                      <Row>
                        <Col xs={24} md={12} lg={24}>
                          <ul>
                            <li className='active'>
                              <div
                                className={cn({
                                  Admin_By: logsData?.added_by === 'Admin',
                                  User_By: logsData?.added_by !== 'Admin',
                                })}
                              >
                                {logsData !== null && Object.keys(logsData).length !== 0 && (
                                  <span>
                                    {moment(logsData.updated_at).format('Do MMM, h:mm a')}
                                  </span>
                                )}
                                {logsData !== null && Object.keys(logsData).length !== 0 && (
                                  <div className='actualVersion'>
                                    <div
                                      className={cn({
                                        Admin_By: logsData?.added_by === 'Admin',
                                        User_By: logsData?.added_by !== 'Admin',
                                        Byadmin: logsData?.added_by === 'Admin',
                                        ByUser: logsData?.added_by !== 'Admin',
                                      })}
                                    >
                                      <h6>{logsData?.added_by === 'Admin' ? 'Admin' : 'User'}</h6>
                                    </div>
                                  </div>
                                )}
                                {!isLoader && (
                                  <>
                                    {logsData !== null && Object.keys(logsData).length > 0 ? (
                                      <ActualVersion
                                        data={logsData}
                                        countryWithRegion={countryWithRegion}
                                      />
                                    ) : (
                                      <p>No logs found</p>
                                    )}
                                  </>
                                )}
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
              <div className='right'>
                <Form onSubmit={wineFormik.handleSubmit}>
                  <div className='top-controll'>
                    <Link to='/panel/WineList' className='back-btn'>
                      <IconBack /> Back to winery list
                    </Link>
                  </div>
                  <div className='block' id='blockone'>
                    <h1 className='common-heading'>
                      {createsNew ? 'Add Winemaker' : 'Edit Profile'}{' '}
                    </h1>
                    <div className='AdduserInfo'>
                      <InputField
                        id='winery_name'
                        name='winery_name'
                        isRequired
                        label='Winery Name'
                        placeholder='Ex. Berbera'
                        onChange={(event) => {
                          wineFormik.setFieldValue('winery_name', event.target.value)
                        }}
                        value={wineFormik.values.winery_name || ''}
                        formik={wineFormik}
                        className={cn('drop_label', {
                          'dot-bar':
                            logsData?.newValues?.winery_name && logsData?.added_by === 'Admin',
                          edit_Bar:
                            logsData?.newValues?.winery_name && logsData?.added_by !== 'Admin',
                        })}
                      />
                    </div>
                    <div className='cus_drop inputField__wrap'>
                      <label
                        className={cn('drop_label', {
                          'dot-bar': logsData?.newValues?.country && logsData?.added_by === 'Admin',
                          edit_Bar: logsData?.newValues?.country && logsData?.added_by !== 'Admin',
                        })}
                      >
                        Country<sup>*</sup>
                      </label>
                      <select value={country} onChange={(e) => handleCountryChange(e)}>
                        <option value='' disabled>
                          Select country
                        </option>
                        {allCountry.map((option) => (
                          <option key={option} value={option.index}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <ShowError formik={wineFormik} id='country' />
                    </div>

                    <Grid>
                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={8}
                        className='padd-right InputSpaceing'
                      >
                        <div className='cus_drop inputField__wrap p-0'>
                          <label
                            className={cn('drop_label', {
                              'dot-bar':
                                logsData?.newValues?.location && logsData?.added_by === 'Admin',
                              edit_Bar:
                                logsData?.newValues?.location && logsData?.added_by !== 'Admin',
                            })}
                          >
                            Region<sup>*</sup>
                          </label>

                          <select
                            id='location'
                            value={wineFormik.values.location}
                            onChange={(e) => handleRegionChange(e)}
                            disabled={wineFormik.values.country === ''}
                          >
                            <option value='' disabled>
                              Select region
                            </option>
                            {regionList.map((option) => (
                              <option key={`reg-${option.id}`} value={option.id}>
                                {option.region_name}
                              </option>
                            ))}
                          </select>
                          <ShowError formik={wineFormik} id='location' />
                        </div>
                      </Grid.Column>
                    </Grid>
                    <div className='AdduserInfo'>
                      <InputField
                        id='winemaker_name'
                        name='winemaker_name'
                        isRequired
                        label='Winemaker Name'
                        placeholder='Ex. Berbera'
                        onChange={(event) => {
                          wineFormik.setFieldValue('winemaker_name', event.target.value)
                        }}
                        value={wineFormik.values.winemaker_name || ''}
                        formik={wineFormik}
                        className={cn('drop_label', {
                          'dot-bar':
                            logsData?.newValues?.winemaker_name && logsData?.added_by === 'Admin',
                          edit_Bar:
                            logsData?.newValues?.winemaker_name && logsData?.added_by !== 'Admin',
                        })}
                      />
                    </div>
                    <div
                      className={cn('drop_label', {
                        'dot-bar':
                          logsData?.newValues?.profile_image && logsData?.added_by === 'Admin',
                        edit_Bar:
                          logsData?.newValues?.profile_image && logsData?.added_by !== 'Admin',
                      })}
                    >
                      <h3 className='common-sub-heading'>Profile Photo</h3>
                    </div>
                    <div className='AdduserInfo'>
                      <div className='uploadField'>
                        <p>Ratio: 3 / 4</p>
                        <div className='profilePic'>
                          <div className='profilePic-inner'>
                            <input
                              id='profile_image'
                              type='file'
                              name=' profile_image'
                              className='input__input'
                              accept='.jpeg,.png,.jpg'
                              formEncType='multipart/form-data'
                              onChange={uploadFileToS3}
                              ref={inputRefProfileImage}
                              title={
                                wineFormik.values?.profile_image === '' ? 'No file chosen' : ''
                              }
                            />
                            <label
                              className={cn('file-input__prof', {
                                'hidden IconHidd': wineFormik.values?.profile_image,
                              })}
                              htmlFor='file-input'
                            />

                            <div className='down-button' />

                            <ReactCrop
                              crop={crop}
                              onComplete={(c) => setCompletedCrop(c)}
                              onChange={(c) => setCrop(c)}
                              aspect={3 / 4}
                              style={{ zIndex: 9 }}
                            >
                              <img
                                ref={imgRef}
                                src={`${wineFormik.values?.profile_image}`}
                                alt=''
                                crossOrigin='anonymous'
                                width='100%'
                                height='auto'
                              />
                            </ReactCrop>

                            {!!completedCrop && (
                              <>
                                <div>
                                  <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                      border: '1px solid black',
                                      objectFit: 'contain',
                                      width: completedCrop.width,
                                      height: completedCrop.height,
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {wineFormik.touched.profile_image && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  margin: 'auto',
                                  textAlign: 'center',
                                  position: 'absolute',
                                  marginTop: '5px',
                                  width: '100%',
                                }}
                              >
                                {wineFormik.errors.profile_image}
                              </p>
                            )}

                            <div className='imageActions'>
                              {!!completedCrop && (
                                <button
                                  className='imageActions__button'
                                  type='button'
                                  onClick={() => uploadCropped()}
                                >
                                  <IconCrop />
                                </button>
                              )}
                              {wineFormik.values?.profile_image && (
                                <button
                                  className='imageActions__button'
                                  type='button'
                                  onClick={() => deleteProfileImage()}
                                >
                                  <IconDelete />
                                </button>
                              )}
                              {wineFormik.values?.profile_image !== '' && (
                                <button
                                  className='imageActions__button'
                                  type='button'
                                  onClick={() => inputRefProfileImage.current.click()}
                                >
                                  <AddIcon />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Popup
                        className='trigerEdit AdditionIfo'
                        trigger={
                          <div className='InfoToggle'>
                            <img src={Info_Icon} alt='Info_Icon' />
                          </div>
                        }
                        content={
                          <>
                            <img
                              src={wineFormik.values?.profile_image || photo_colr}
                              alt=''
                              className='imgselector'
                            />
                            <div className='infoContaent'>
                              <p>
                                Profile picture is displayed in the winery account icon and on the
                                winery card on homepage and wineries page.
                              </p>
                            </div>
                          </>
                        }
                        on={['hover', 'click']}
                        position='bottom right'
                      />
                    </div>
                    <br></br>
                  </div>
                  <div className='block' id='blocktwo'>
                    <div className='Header'>
                      <h3 className='common-sub-heading'>Contact Details</h3>
                    </div>
                    <div className='AdduserInfo'>
                      <InputField
                        id='main_contact_email'
                        name='main_contact_person'
                        label='Main Contact'
                        placeholder='Ex. Richard Johnson'
                        onChange={(event) => {
                          wineFormik.setFieldValue('main_contact_person', event.target.value)
                        }}
                        value={wineFormik.values.main_contact_person}
                        formik={wineFormik}
                      />
                    </div>
                    <div className='AdduserInfo'>
                      {userId ? (
                        <InputField
                          isRequired
                          id='email'
                          name='commercial_email'
                          label='Email Address'
                          placeholder='Ex. info@gmail.com'
                          onChange={(event) => {
                            wineFormik.setFieldValue('email', event.target.value)
                          }}
                          value={wineFormik.values.email}
                          formik={wineFormik}
                          disabled={wineFormik.values.email}
                        />
                      ) : (
                        <InputField
                          isRequired
                          id='email'
                          name='commercial_email'
                          label='Email Address'
                          placeholder='Ex. info@gmail.com'
                          onChange={(event) => {
                            wineFormik.setFieldValue('email', event.target.value)
                          }}
                          value={wineFormik.values.email}
                          formik={wineFormik}

                          // disabled={wineFormik.values.email ? true : false}
                        />
                      )}
                    </div>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div className='PhoneInputList'>
                        <label>
                          Phone Number<sup>*</sup>
                        </label>
                        <PhoneInput
                          defaultCountry={country_code}
                          id='phone_no'
                          international
                          value={wineFormik.values.phone_no}
                          onCountryChange={(value) => {
                            if (value) {
                              setCountryCode(value)
                              setPhoneCode(getCountryCallingCode(value))
                            }
                          }}
                          // error={
                          //   value
                          //     ? isValidPhoneNumber(value)
                          //       ? ''
                          //       : 'Invalid phone number'
                          //     : 'Phone number required'
                          // }
                          onChange={(value) => {
                            wineFormik.setFieldValue('phone_no', value)
                          }}
                        />
                        {wineFormik.touched.phone_no && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              margin: 'auto',
                              position: 'absolute',
                              marginTop: '5px',
                              width: '100%',
                            }}
                          >
                            {wineFormik.values.phone_no &&
                            isValidPhoneNumber(wineFormik.values.phone_no)
                              ? ''
                              : 'Phone number is not valid'}
                          </p>
                        )}
                        {!wineFormik.values.phone_no && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              margin: 'auto',
                              position: 'absolute',
                              marginTop: '5px',
                              width: '100%',
                            }}
                          >
                            Phone number is not valid
                          </p>
                        )}
                      </div>
                    </Grid.Column>{' '}
                    <div className='AdduserInfo'>
                      <InputField
                        id='winery_address'
                        name='winery_address'
                        label='Physical address'
                        placeholder='Ex. Address'
                        onChange={(event) => {
                          wineFormik.setFieldValue('winery_address', event.target.value)
                        }}
                        value={wineFormik.values.winery_address || ''}
                        formik={wineFormik}
                        className={cn('drop_label', {
                          'dot-bar':
                            logsData?.newValues?.winery_address && logsData?.added_by === 'Admin',
                          edit_Bar:
                            logsData?.newValues?.winery_address && logsData?.added_by !== 'Admin',
                        })}
                      />
                    </div>{' '}
                    <div className='AdduserInfo'>
                      <InputField
                        id='vat'
                        name='vat'
                        type='tel'
                        label='VAT'
                        placeholder='Ex. VAT'
                        onChange={(event) => {
                          wineFormik.setFieldValue('vat', event.target.value)
                        }}
                        value={wineFormik.values.vat}
                        formik={wineFormik}
                      />
                    </div>
                    <div className='AdduserInfo'>
                      <InputField
                        id='registration_number'
                        name='registration_number'
                        label='Business registration number'
                        placeholder='Ex. registration number'
                        onChange={(event) => {
                          wineFormik.setFieldValue('registration_number', event.target.value)
                        }}
                        value={wineFormik.values.registration_number}
                        formik={wineFormik}
                      />
                    </div>
                    <div className='cus_drop inputField__wrap'>
                      <label>Business registration country</label>
                      <select
                        value={wineFormik.values.registration_country}
                        onChange={(event) => {
                          wineFormik.setFieldValue('registration_country', event.target.value)
                        }}
                      >
                        <option value=''></option>
                        {registrationCountries.map(({ id, country }) => (
                          <option key={id} value={id}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <ShowError formik={wineFormik} id='registration_country' />
                    </div>
                  </div>
                  <div className='block' id='blockthree'>
                    <div className='Header'>
                      <h3 className='common-sub-heading'>Letter of intent</h3>
                      <p>Upload letter of intent Document</p>
                    </div>
                    <div className='uploadField'>
                      <div className='profilePic'>
                        <div className='profilePic-inner'>
                          <input
                            id='contract_file'
                            type='file'
                            name=' contract'
                            className='input__input'
                            onChange={uploadFileToS3File}
                            accept='.pdf,.doc,.docx'
                            formEncType='multipart/form-data'
                            ref={inputRefIntentFile}
                            disabled={wineFormik.values?.contract_file}
                          />
                          <label
                            className={cn('file-input__prof', {
                              IconHidd: wineFormik.values?.contract_file,
                            })}
                            htmlFor='contract'
                          >
                            {wineFormik.values?.contract_file && (
                              <img style={{ width: 150, height: 150 }} src={doc} alt='' />
                            )}
                          </label>
                          {wineFormik.touched.contract_file && (
                            <p
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                margin: 'auto',
                                textAlign: 'center',
                                position: 'absolute',
                                marginTop: '5px',
                                width: '100%',
                              }}
                            >
                              {wineFormik.errors.contract_file}
                            </p>
                          )}

                          {wineFormik.values?.contract_file && (
                            <div className='editand_DltBtn'>
                              <button
                                className='DeltBtnsIcon'
                                type='button'
                                onClick={() => deleteCoverImageContent()}
                              >
                                <IconDelete />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className='file-input__importent'>
                          <p>Maximum document size 16mb (PDF, docx, doc)</p>
                        </div>
                      </div>
                    </div>

                    <div className='btns-spacing steps_buuton'>
                      <ButtonCustom
                        type='button'
                        title='Cancel'
                        customClass='previous'
                        onClick={() => history.push('/panel/WineList')}
                      />

                      <ButtonCustom type='submit' title='Save' customClass='Continue' />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </MainLayout>
    </>
  )
}
export default EditProfilePage
