import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getAllBottleList = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/getAllBottles`, data, headers)
}

const getAllBottleDropDownSpecifications = (headers) => {
  return fetch('get', `${API_HOST}/winers/api/v1/getBottleSpecification`, {}, headers)
}
const addBottles = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/createWineBottle`, data, headers)
}
const updateBottle = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/updateWineBottle`, data, headers)
}

const getAllWineryListForDropDown = (headers) => {
  return fetch('get', `${API_HOST}/winers/api/v1/winerylist`, {}, headers)
}

const getBottleDetail = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/getBottleDetail`, data, headers)
}

const deleteBottle = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/deleteBottle`, data, headers)
}

const publishUnpublishBottle = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/updateBottleStatus`, data, headers)
}

const getAllActiveRegions = (headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/region/all/active`, {}, headers)
}
const bulkCreateBottles = (data, headers) => {
  return fetch('post', `${API_HOST}/winers/api/v1/bulkCreateBottles`, data, headers)
}
export const BottlesService = {
  getAllBottleList,
  getAllBottleDropDownSpecifications,
  addBottles,
  updateBottle,
  getAllWineryListForDropDown,
  getBottleDetail,
  deleteBottle,
  publishUnpublishBottle,
  getAllActiveRegions,
  bulkCreateBottles,
}
